<div *ngIf="cartsList | async as carts">
    <div *ngIf="carts?.length > 0">
        <div class="mb-3 mt-3 export-btn">
            <button class="btn cstm-btn-primary" (click)="downloadCsv(carts)">
                <i class="fa fa-download mr-2"></i>{{"cartPage.csv" | cxTranslate}}
            </button>
            <button class="btn cstm-btn-light ml-3" (click)="downloadPDF(carts)">
                <i class="fa fa-download mr-2"></i>{{"cartPage.pdf" | cxTranslate}}
            </button>
        </div>
    </div>
</div>